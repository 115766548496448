import React, { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import HomePage from './pages/home/home.page';
import { IAppContext } from './context';
import AppContext from './context/app.context';
import TermsAndConditionsPage
  from './pages/terms-and-conditions/terms-and-conditions.page';
import PrivacyPolicyPage from './pages/privacy-policy/privacy-policy.page';

function App(props: IAppContext) {
  const [links, setLinks] = useState(props.links);
  const context: IAppContext = {
    links,
    setLinks,
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage/>,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditionsPage/>,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage/>,
    },
  ]);

  return (
    <AppContext.Provider value={context}>
      <RouterProvider router={router}/>
    </AppContext.Provider>
  );
}

export default App;
