import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import AppContext, { IAppContext } from '../context/app.context';

export const Footer = () => {
  const {links} = useContext(AppContext) as IAppContext;
  return <footer className="bg-light d-flex flex-wrap justify-content-end py-3 py-lg-5 px-3 px-lg-5">
    <div className="container d-block d-md-none">
      <p className="m-0 text-center text-muted">Copyright © Snoween 2024</p>
    </div>
    <div className={'d-none d-md-inline-flex d-flex py-md-3'}>
      <ul className="nav nav-pills pe-5">
        <li className="nav-item me-md-3">
          <a href="/#about-us"
             className="nav-link text-black"
             aria-current="page">About us</a>
        </li>
        <li className="nav-item ms-md-3">
          <a href="/#pricing"
             className="nav-link text-black">Pricing</a>
        </li>
      </ul>
      <Link className="py-2 btn btn-danger rounded-6"
            target={'_blank'}
            to={links?.tryNow ?? ''}>Login or Sign up</Link>
    </div>
  </footer>;
};