import React, { useContext } from 'react';
import { IAppContext } from '../../context';
import AppContext from '../../context/app.context';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

const PrivacyPolicyPage = (props: IAppContext) => {
  const {links} = useContext(AppContext) as IAppContext;
  return (
    <>
      <Header/>
      <main>
        <h1 className="visually-hidden">Privacy Policy</h1>

        <div className="px-4 py-5 my-5">
          <div className="col-lg-5 mx-auto">
            <div className={'text-center mb-5'}>
              <img src="/img/snoween-logo-white.svg"
                   alt="Snoween Logo White"
                   className="w-80--px mx-auto d-block my-4 rounded-circle bg-primary"/>
              <h1 className="display-5 fw-bold">Privacy Policy</h1>
            </div>
            <p><strong>Effective Date: MAR-2024</strong></p>
            <p className={'lead mb-4'}>Welcome to Snoween LLC! By using our
              platform and services, you
              agree to comply with these terms and conditions. Please read
              them carefully.</p>

            <h2>1. Acceptance of Terms</h2>
            <p className={'lead mb-4'}>By registering for an account, accessing,
              or using our
              platform, you acknowledge that you have read, understood, and
              agree to be bound by these Terms and Conditions.</p>

            <h2>2. Services Offered</h2>
            <p className="lead mb-4">Snoween LLC provides e-commerce solutions
              for small
              businesses in the United States.
            </p>
            <p className="lead mb-4">Users can sell products online or charge
              customers using our
              mobile app with tap-to-pay functionality.
            </p>
            <p className="lead mb-4">Earnings are deposited directly into users’
              bank accounts
              via Stripe, our third-party payment processor.
            </p>

            <h2>3. Account Registration</h2>
            <p className="lead mb-4">Users must provide accurate information
              during account
              creation.
            </p>
            <p className="lead mb-4">You are responsible for maintaining the
              confidentiality of
              your account and password.
            </p>
            <p className="lead mb-4">You agree to use the platform for lawful
              purposes only.</p>

            <h2>4. Pricing and Subscription Plans</h2>
            <p className="lead mb-4">We offer a free tier and paid subscription
              tiers. Detailed
              pricing and features will be communicated on our website.
            </p>
            <p className="lead mb-4">Paid tiers are billed on a recurring basis
              unless
              canceled.
            </p>

            <h2>5. Refund Policy</h2>
            <p className="lead mb-4">Free Tier: No fees apply, so no refunds are
              applicable.</p>
            <p className="lead mb-4">Paid Tiers:</p>
            <p className="lead mb-4">Refunds for unused portions of a
              subscription period are not offered except in cases where Snoween
              LLC fails to deliver services as promised.</p>
            <p className="lead mb-4">Users may cancel their subscription at any
              time, but fees for the current billing cycle are
              non-refundable.</p>

            <h2>6. Dispute Resolution</h2>
            <p className="lead mb-4">All disputes arising from the use of our
              platform must first
              be addressed through good-faith negotiations with Snoween LLC.
            </p>
            <p className="lead mb-4">If unresolved, disputes will be handled
              through arbitration
              under [state-specific arbitration laws], waiving the right to
              a jury trial.
            </p>

            <h2>7. Payment Processing</h2>
            <p className="lead mb-4">Payments are processed securely through
              Stripe.</p>
            <p className="lead mb-4">Users must comply with Stripe's terms of
              service and privacy
              policies. Snoween LLC is not responsible for delays or issues
              caused by Stripe.
            </p>

            <h2>8. Restrictions and Prohibited Use</h2>
            <p className="lead mb-4">Users may not sell illegal goods,
              counterfeit items, or
              products that violate intellectual property laws.
            </p>
            <p className="lead mb-4">Snoween LLC reserves the right to terminate
              accounts found
              in violation of these restrictions.
            </p>

            <h2>9. Privacy Policy</h2>
            <p className="lead mb-4">Snoween LLC values your privacy and
              complies with applicable
              laws, including GDPR and CCPA where relevant.
            </p>
            <p className="lead mb-4">Your data is processed securely and only
              for purposes
              outlined in our [Privacy Policy].
            </p>

            <h2>10. Limitation of Liability</h2>
            <p className="lead mb-4">Snoween LLC is not liable for indirect,
              incidental, or
              consequential damages arising from the use of our platform.
            </p>
            <p className="lead mb-4">Total liability is limited to the amount
              paid for services
              during the previous 12 months.
            </p>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
};

export { PrivacyPolicyPage as default };
