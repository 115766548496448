import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IAppContext } from '../../context';
import AppContext from '../../context/app.context';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

const HomePage = (props: IAppContext) => {
  const {links} = useContext(AppContext) as IAppContext;
  return (
    <>
      <Header/>
      <main>
        <div className="d-md-flex flex-md-equal w-100 my-md-4 px-md-4 position-relative bg-hero-image">
          <div className="d-flex py-lg-5 text-center overflow-hidden w-100 y-100">
            <div className="align-self-center my-3 mx-auto">
              <h2 className="display-5 fs-2 text-white mt-4 mt-lg-5">
                <p className={'mb-0'}>Would you like to have your own
                  online</p>
                <p>store <span className={'fw-bolder'}>without paying anything upfront?</span>
                </p>
              </h2>
              <div className={'flex-column flex-md-row mt-5'}>
                <Link className="btn btn-light rounded-6 text-danger mt-md-3 mb-4 mb-lg-5 px-5"
                      target={'_blank'}
                      to={links?.tryNow ?? ''}><strong>TRY NOW!</strong></Link>
                <div className={'flex-md-row'}>
                  <Link className="px-5"
                        target={'_blank'}
                        to={links?.googlePlay ?? ''}>
                    <img src={'/img/google-play-739x257.png'}
                         className={'bg-google-play-image w-140--px'}
                         alt={'google-play-badged'}/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id={'about-us'}
             className="d-md-flex flex-md-equal w-100 my-md-4 px-md-4">
          <div className="d-flex bg-light me-md-4 px-3 px-lg-5 text-center overflow-hidden">
            <div className="align-self-center px-2 px-lg-5 py-4 py-lg-0 text-start">
              <h2 className={'fw-bold mb-3 mb-md-0'}>Snoween, the multi-store
                eCommerce that
                adapts to you!</h2>
              <p className="lead mt-2 mt-md-5">We are a software as service
                (SaaS) that
                allows you to create a multi-store eCommerce, where each store
                has its own inventory, warehouse and prices.</p>
            </div>
          </div>
          <div className="bg-warning pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3">
              <img className={'img-fluid'}
                   src={'/img/tablet1.png'}/>
            </div>
          </div>
        </div>

        <div className="d-md-flex flex-md-equal w-100 my-md-4 px-md-4">
          <div className="d-flex flex-column bg-light py-md-4 border border-3 text-center overflow-hidden">
            <div className={'mt-5 mb-0 fs-1'}>
              <p className={'fs-2'}><strong>With us, you can:</strong></p>
            </div>
            <div className="d-flex flex-column flex-md-row me-md-3 mb-5 pt-3 px-3 px-md-5">
              <div className="d-flex flex-column justify-content-around ms-lg-4 me-lg-5">
                <div className={'mb-4'}>
                  <svg width="70"
                       height="70"
                       viewBox="0 0 70 70"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2.33333C0 1.04467 1.04467 0 2.33333 0H9.33333C10.404 0 11.3373 0.728694 11.597 1.76742L13.4885 9.33333H67.6666C68.3608 9.33333 69.0189 9.64238 69.4622 10.1765C69.9055 10.7107 70.0879 11.4144 69.96 12.0967L62.96 49.43C62.7531 50.5336 61.7895 51.3333 60.6667 51.3333H56H23.3333H18.6667C17.5438 51.3333 16.5802 50.5336 16.3733 49.43L9.38612 12.1651L7.51152 4.66667H2.33333C1.04467 4.66667 0 3.622 0 2.33333ZM14.4782 14L20.6032 46.6667H58.7302L64.8552 14H14.4782ZM23.3333 51.3333C18.1787 51.3333 14 55.512 14 60.6667C14 65.8213 18.1787 70 23.3333 70C28.488 70 32.6667 65.8213 32.6667 60.6667C32.6667 55.512 28.488 51.3333 23.3333 51.3333ZM56 51.3333C50.8453 51.3333 46.6667 55.512 46.6667 60.6667C46.6667 65.8213 50.8453 70 56 70C61.1546 70 65.3333 65.8213 65.3333 60.6667C65.3333 55.512 61.1546 51.3333 56 51.3333ZM23.3333 56C25.9107 56 28 58.0893 28 60.6667C28 63.244 25.9107 65.3333 23.3333 65.3333C20.756 65.3333 18.6667 63.244 18.6667 60.6667C18.6667 58.0893 20.756 56 23.3333 56ZM56 56C58.5773 56 60.6667 58.0893 60.6667 60.6667C60.6667 63.244 58.5773 65.3333 56 65.3333C53.4227 65.3333 51.3333 63.244 51.3333 60.6667C51.3333 58.0893 53.4227 56 56 56Z"
                          fill="#1A9ABB"/>
                  </svg>
                </div>
                <p>Create and manage as many stores as you want, with
                  complete <strong>autonomy</strong> and <strong>flexibility</strong>.
                </p>
              </div>
              <div className="d-flex flex-column justify-content-around ms-lg-5 me-lg-5">
                <div className={'mb-4'}>
                  <svg width="70"
                       height="70"
                       viewBox="0 0 70 70"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 17.5C0 17.5 0 8.75 8.75 8.75H61.25C61.25 8.75 70 8.75 70 17.5V43.75C70 43.75 70 52.5 61.25 52.5H43.75C43.75 55.4167 44.1146 57.6042 44.8438 59.0625H48.125C49.3331 59.0625 50.3125 60.0419 50.3125 61.25C50.3125 62.4581 49.3331 63.4375 48.125 63.4375H21.875C20.6669 63.4375 19.6875 62.4581 19.6875 61.25C19.6875 60.0419 20.6669 59.0625 21.875 59.0625H25.1562C25.8854 57.6042 26.25 55.4167 26.25 52.5H8.75C8.75 52.5 0 52.5 0 43.75V17.5ZM6.11822 13.7574C5.65659 14.0652 5.29424 14.5068 5.00687 15.0816C4.71093 15.6734 4.54116 16.3183 4.45219 16.8521C4.40944 17.1086 4.38975 17.3128 4.38097 17.4357C4.37733 17.4867 4.37568 17.522 4.375 17.5395V43.75C4.375 45.1719 4.71791 45.9475 5.00741 46.3818C5.31516 46.8434 5.75682 47.2058 6.33156 47.4931C6.92343 47.7891 7.56827 47.9588 8.10206 48.0478C8.35858 48.0906 8.56278 48.1102 8.68573 48.119C8.73672 48.1227 8.77207 48.1243 8.78949 48.125H61.25C62.6719 48.125 63.4475 47.7821 63.8818 47.4926C64.3434 47.1848 64.7058 46.7432 64.9931 46.1684C65.2891 45.5766 65.4588 44.9317 65.5478 44.3979C65.5906 44.1414 65.6102 43.9372 65.619 43.8143C65.6227 43.7633 65.6243 43.7279 65.625 43.7105V17.5C65.625 16.0781 65.2821 15.3025 64.9926 14.8682C64.6848 14.4066 64.2432 14.0442 63.6684 13.7569C63.0766 13.4609 62.4317 13.2912 61.8979 13.2022C61.6414 13.1594 61.4372 13.1398 61.3143 13.131C61.2633 13.1273 61.228 13.1257 61.2105 13.125H8.75C7.32806 13.125 6.55246 13.4679 6.11822 13.7574Z"
                          fill="#FFB300"/>
                  </svg>
                </div>
                <p>Each store has a <strong>public URL where you can showcase
                  your products</strong> and attract your customers.
                </p>
              </div>
              <div className="d-flex flex-column justify-content-around ms-lg-5 me-lg-4">
                <div className={'mb-4'}>
                  <svg width="70"
                       height="70"
                       viewBox="0 0 70 70"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M61.25 4.375H8.75C3.91751 4.375 0 8.29251 0 13.125C0 17.9575 3.91751 21.875 8.75 21.875L8.75 56.875C8.75 61.7075 12.6675 65.625 17.5 65.625H52.5C57.3325 65.625 61.25 61.7075 61.25 56.875V21.875C66.0825 21.875 70 17.9575 70 13.125C70 8.29251 66.0825 4.375 61.25 4.375ZM13.125 56.875L13.125 13.125H39.375V61.25H17.5C15.0838 61.25 13.125 59.2912 13.125 56.875ZM48.125 13.125L48.125 61.25H52.5C54.9162 61.25 56.875 59.2912 56.875 56.875V13.125H48.125Z"
                          fill="#F81B0D"/>
                  </svg>
                </div>
                <p>It's like having a free catalog, but with the option <strong>to
                  sell online whenever want you want</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id={'pricing'}
             className="d-md-flex flex-md-equal w-100 my-md-4 px-md-4">
          <div className="bg-primary me-md-4 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3">
              <img className={'img-fluid'}
                   src={'/img/tablet2.png'}/>
            </div>
          </div>
          <div className="d-flex bg-light px-3 px-lg-5 text-center overflow-hidden">
            <div className="align-self-center px-2 px-lg-5 py-4 py-lg-0 text-start">
              <h2 className={'fw-bold mb-3 mb-md-0'}>No Subscription, only pay
                for sale!</h2>
              <p className="lead mt-2 mt-md-5"><strong>Yes, you only
                pay for sale!</strong> With no subscription.
                We
                are ideal solution for entrepeneurs who want to have their own
                online business without risks or complications.</p>
            </div>
          </div>
        </div>

        <div className="d-md-flex flex-md-equal w-100 my-md-4 px-md-4">
          <div className="bg-danger py-md-4 text-center overflow-hidden">
            <div className="my-3 p-3">
              <h2 className="display-5 fs-2 text-white">
                <p className={'my-0 my-md-3'}>What are you waiting for? <strong>Create
                  your</strong></p>
                <p><strong>Snoween store today and start selling.</strong></p>
              </h2>
              <Link className="btn btn-outline-light mt-3 px-5 border border-3 rounded-6"
                    target={'_blank'}
                    to={links?.tryNow ?? ''}><strong>TRY NOW!</strong></Link>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
};

export { HomePage as default };
